import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <>
            <section className="wed-hom-footer">
                <div className="container">
                    <div className="row foot-supp">
                        <h2>
                            <span>Free support:</span>+91 04424322858
                            &nbsp;&nbsp;|&nbsp;&nbsp; <span>Email:</span>
                            cnnm1985@gmail.com
                        </h2>
                    </div>
                    <div className="row wed-foot-link wed-foot-link-1">
                        <div className="col-md-4">
                            <h4>Get In Touch</h4>
                            <p>Address: 3812 Lena Lane City Jackson Mississippi</p>
                            <p>
                                Phone: <a href="tel: +91 04424322858"> +91 044 2432 2858</a>
                            </p>
                            <p>
                                Email: <a href="mailto:cnnm1985@gmail.com">cnnm1985@gmail.com</a>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h4>HELP &amp; SUPPORT</h4>
                            <ul>
                                <li>
                                    <Link to='/About'>

                                        <a>About company</a>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#!">Contact us</a>
                                </li>
                                <li>
                                    <a href="#!">Feedback</a>
                                </li>
                                <li>
                                    <Link to='/Faq'>
                                        <a>FAQs</a>
                                    </Link>
                                </li>
                                <li>


                                    <a href="about-us.html#testimonials">Testimonials</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 fot-soc">
                            <h4>SOCIAL MEDIA</h4>
                            <ul>
                                <li>
                                    <a href="#!">
                                        <img src="images/social/1.png" alt="" loading="lazy" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <img src="images/social/2.png" alt="" loading="lazy" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <img src="images/social/3.png" alt="" loading="lazy" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <img src="images/social/5.png" alt="" loading="lazy" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row foot-count">
                        <p>
                            Company name Site - Trusted by over thousands of Boys &amp; Girls for
                            successfull marriage.{" "}

                            <Link to='/Sigin'>
                                <a className="btn btn-primary btn-sm">
                                    Join us today !

                                </a>
                            </Link>
                        </p>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* COPYRIGHTS */}
            <section>
                <div className="cr">
                    <div className="container">
                        <div className="row">
                            <p>
                                Copyright © <span id="cry">2023</span>{" "}
                                <a href="#!" target="_blank">
                                    Company.com
                                </a>{" "}
                                All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer