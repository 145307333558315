import React from 'react'

const Services = () => {
    return (
        <>
           
            {/* REGISTER */}
            <section>
                <div className="pg-ser">
                    <div className="inn">
                        <ul>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/19.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Matrimony Wedding Services</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/14.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>The Ceremony</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/15.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Photography &amp; Video</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/16.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Food Catering</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/17.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Decorations</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/22.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Wedding Halls</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/21.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>Wedding Registry</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="gal-im">
                                    <img
                                        src="images/couples/20.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding Matrimony</span>
                                        <h4>The Perfect Cake</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Aenean commodo ligula.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END */}
         
        </>

    )
}

export default Services