import React, { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IoCaretDown } from "react-icons/io5";
// import HomeCarosole from './HomeCarosole';
// import $ from 'jquery';
// import '../../node_modules/popper.js/dist/popper.js';
// @ts-ignore
import '../../node_modules/popper.js/dist/popper.js';

import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';


import 'select2';
import 'slick-carousel';
// import { IoCaretDown } from "react-icons/io5";
import HomeCarosole from './HomeCarosole';
import MomentsSec from './MomentsSec.jsx'
const Home = () => {
    useEffect(() => {
        const initializeSliders = () => {
            // Initialize sliders if there are elements with class 'slid-inn'
            var $lis = $('.slid-inn');
            if ($lis.length > 0) {
                // COMMON SLIDER
                $('.slider3').slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false
                        }
                    }]
                });

                // HOME PAGE WRECENT COUPLES
                $('.couple-sli').slick({
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [{
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: false
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false
                        }
                    }
                    ]
                });

                // HOME PAGE BANNER SLIDER
                $('.ban-sli').slick({
                    infinite: true,
                    fade: true,
                    cssEase: 'linear',
                    autoplay: true,
                    autoplaySpeed: 6000
                });

                // HOME PAGE WRECENT COUPLES
                $('.hom-qui-acc-sli').slick({
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    dots: true,
                    autoplaySpeed: 3000,
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            centerMode: false
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                            arrows: false
                        }
                    }
                    ]
                });
            }
        };

        // Call the initialization function when the component mounts
        initializeSliders();

        // Clean up the sliders when the component unmounts
        return () => {
            $('.slider3, .couple-sli, .ban-sli, .hom-qui-acc-sli').slick('unslick');
        };
    }, []);
    return (

        <div>


            {/* END MOBILE MENU POPUP */}
            {/* MOBILE USER PROFILE MENU POPUP */}

            {/* END USER PROFILE MENU POPUP */}
            {/* BANNER & SEARCH */}
            <section>
                <div className="str">
                    <div className="hom-head">
                        <div className="container">
                            <div className="row">
                                <div className="hom-ban">
                                    <div className="ban-tit">
                                        <span>
                                            "Love, Trust, Companionship
                                        </span>


                                        <h1>
                                            Find Your   <b> Perfect Match </b> Here! "
                                        </h1>



                                    </div>
                                    <div className="ban-search chosenini">
                                        <form>
                                            <ul>
                                                <li className="sr-look">
                                                    <div className="form-group">
                                                        <label>I'm looking for</label>
                                                        <select className="chosen-select">
                                                            <option value="">I'm looking for</option>
                                                            <option value="Men">Men</option>
                                                            <option value="Women">Women</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li className="sr-age">
                                                    <div className="form-group">
                                                        <label>Age</label>
                                                        <select className="chosen-select">
                                                            <option value="">Age</option>
                                                            <option value="">18 to 30</option>
                                                            <option value="">31 to 40</option>
                                                            <option value="">41 to 50</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li className="sr-reli">
                                                    <div className="form-group">
                                                        <label>Religion</label>
                                                        <select className="chosen-select">
                                                            <option>Religion</option>

                                                            <option>Hindu</option>

                                                        </select>
                                                    </div>
                                                </li>
                                                <li className="sr-cit">
                                                    <div className="form-group">
                                                        <label>City</label>
                                                        <select className="chosen-select">

                                                            <option value="Ariyalur">Ariyalur</option>
                                                            <option value="Chengalpattu">Chengalpattu</option>
                                                            <option value="Chennai">Chennai</option>
                                                            <option value="Coimbatore">Coimbatore</option>
                                                            <option value="Cuddalore">Cuddalore</option>
                                                            <option value="Dharmapuri">Dharmapuri</option>
                                                            <option value="Dindigul">Dindigul</option>
                                                            <option value="Erode">Erode</option>
                                                            <option value="Kallakurichi">Kallakurichi</option>
                                                            <option value="Kanchipuram">Kanchipuram</option>
                                                            <option value="Kanniyakumari">Kanniyakumari (Kanyakumari)</option>
                                                            <option value="Karur">Karur</option>
                                                            <option value="Krishnagiri">Krishnagiri</option>
                                                            <option value="Madurai">Madurai</option>
                                                            <option value="Mayiladuthurai">Mayiladuthurai</option>
                                                            <option value="Nagapattinam">Nagapattinam</option>
                                                            <option value="Namakkal">Namakkal</option>
                                                            <option value="Nilgiris">Nilgiris</option>
                                                            <option value="Perambalur">Perambalur</option>
                                                            <option value="Pudukkottai">Pudukkottai</option>
                                                            <option value="Ramanathapuram">Ramanathapuram</option>
                                                            <option value="Ranipet">Ranipet</option>
                                                            <option value="Salem">Salem</option>
                                                            <option value="Sivaganga">Sivaganga</option>
                                                            <option value="Tenkasi">Tenkasi</option>
                                                            <option value="Thanjavur">Thanjavur</option>
                                                            <option value="Theni">Theni</option>
                                                            <option value="Thoothukudi">Thoothukudi (Tuticorin)</option>
                                                            <option value="Tiruchirappalli">Tiruchirappalli (Trichy)</option>
                                                            <option value="Tirunelveli">Tirunelveli</option>
                                                            <option value="Tirupathur">Tirupathur</option>
                                                            <option value="Tiruppur">Tiruppur</option>
                                                            <option value="Tiruvallur">Tiruvallur (Thiruvallur)</option>
                                                            <option value="Tiruvannamalai">Tiruvannamalai (Thiruvannamalai)</option>
                                                            <option value="Tiruvarur">Tiruvarur (Thiruvarur)</option>
                                                            <option value="Vellore">Vellore</option>
                                                            <option value="Viluppuram">Viluppuram</option>
                                                            <option value="Virudhunagar">Virudhunagar</option>
                                                        </select>

                                                    </div>
                                                </li>
                                                <li className="sr-btn">
                                                    <input type="submit" defaultValue="Search" />
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* BANNER SLIDER */}
            <section>
                <div className="hom-ban-sli">
                    <div>
                        <ul className="ban-sli">
                            <li>
                                <div className="image">
                                    <img src="https://www.alfaazphotography.com/wp-content/uploads/2021/02/Caledon-Country-Club-0093.jpg" alt="" loading="lazy" />
                                </div>
                            </li>
                            <li>
                                <div className="image">
                                    <img src="https://www.linandjirsa.com/wp-content/uploads/indian-wedding-photograph.jpg" alt="" loading="lazy" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* QUICK ACCESS */}
            <section>
                <div className="str home-acces-main">
                    <div className="container">
                        <div className="row">
                            {/* BACKGROUND SHAPE */}
                            <div className="wedd-shap">
                                <span className="abo-shap-1" />
                                <span className="abo-shap-4" />
                            </div>
                            {/* END BACKGROUND SHAPE */}
                            <div className="home-tit">
                                <p>Quick Access</p>
                                <h2>
                                    <span>Our Services</span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                            <div className="home-acces">
                                <ul className="hom-qui-acc-sli">
                                    <li>
                                        <div className="wow fadeInUp hacc hacc1" data-wow-delay="0.1s">
                                            <div className="con">
                                                <img src="images/icon/user.png" alt="" loading="lazy" />
                                                <h4>Browse Profiles</h4>
                                                <p>1200+ Profiles</p>
                                                <a href="all-profiles.html">View more</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wow fadeInUp hacc hacc2" data-wow-delay="0.2s">
                                            <div className="con">
                                                <img src="images/icon/gate.png" alt="" loading="lazy" />
                                                <h4>Wedding</h4>
                                                <p>1200+ Profiles</p>
                                                <a href="wedding-video.html">View more</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                            <div className="con">
                                                <img src="images/icon/couple.png" alt="" loading="lazy" />
                                                <h4>All Services</h4>
                                                <p>1200+ Profiles</p>
                                                <a href="services.html">View more</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                            <div className="con">
                                                <img src="images/icon/hall.png" alt="" loading="lazy" />
                                                <h4>Join Now</h4>
                                                <p>Start for free</p>
                                                <a href="plans.html">Get started</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                            <div className="con">
                                                <img
                                                    src="images/icon/photo-camera.png"
                                                    alt=""
                                                    loading="lazy"
                                                />
                                                <h4>Photo gallery</h4>
                                                <p>1200+ Profiles</p>
                                                <a href="photo-gallery.html">View more</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                            <div className="con">
                                                <img src="images/icon/cake.png" alt="" loading="lazy" />
                                                <h4>Blog &amp; Articles</h4>
                                                <p>Start for free</p>
                                                <a href="blog.html">Get started</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* TRUST BRANDS */}
            {/* <section>
            <div className="hom-cus-revi">
                <div className="container">
                    <div className="row">
                        <div className="home-tit">
                            <p>trusted brand</p>
                            <h2>
                                <span>
                                    Trust by <b className="num">1500</b>+ Couples
                                </span>
                            </h2>
                            <span className="leaf1" />
                            <span className="tit-ani-" />
                        </div>
                        <div className="slid-inn cus-revi">
                            <ul className="slider3">
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="./images/cardTop.jpg" alt="" loading="lazy" className='img-fluid' />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.postoast.com%2Fsouth-indian-bridal-look%2F&psig=AOvVaw28GoPd_VmCZctC8L1YpX9Y&ust=1713522800314000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqGAoTCLjcqoHIy4UDFQAAAAAdAAAAABCAAQ" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.postoast.com%2Fsouth-indian-bridal-look%2F&psig=AOvVaw28GoPd_VmCZctC8L1YpX9Y&ust=1713522800314000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqGAoTCLjcqoHIy4UDFQAAAAAdAAAAABCAAQ" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.postoast.com%2Fsouth-indian-bridal-look%2F&psig=AOvVaw28GoPd_VmCZctC8L1YpX9Y&ust=1713522800314000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqGAoTCLjcqoHIy4UDFQAAAAAdAAAAABCAAQ" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="cta-full-wid">
                            <a href="#!" className="cta-dark">
                                More customer reviews
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

            <section>
                <div className="hom-cus-revi">
                    <div className="container">
                        <div className="row">
                            <div className="home-tit">
                                <p>trusted brand</p>
                                <h2>
                                    <span>
                                        Trust by <b className="num">1500</b>+ Couples
                                    </span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                            <div className="slid-inn cus-revi">
                                <div className="slider3">
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>
                                    <div className="carosolePadd">
                                        <div className="cus-revi-box">

                                            <div className="imgDiv mb-0">
                                                {/* <img src="https://www.postoast.com/wp-content/uploads/2023/12/South-Indian-wedding-look.jpg" alt=""
      class="img-fluid"> */}
                                                {/* <i className="cir-com cir-1" />
                                        <i className="cir-com cir-2" />
                                        <i className="cir-com cir-3" /> */}
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s,
                                            </p>
                                            <h5>Jack danial</h5>
                                            <span>New york</span>
                                        </div>




                                    </div>









                                    {/* </li> */}
                                    {/* <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="images/user/2.jpg" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="images/user/3.jpg" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="cus-revi-box">
                                        <div className="revi-im">
                                            <img src="images/user/5.jpg" alt="" loading="lazy" />
                                            <i className="cir-com cir-1" />
                                            <i className="cir-com cir-2" />
                                            <i className="cir-com cir-3" />
                                        </div>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,{" "}
                                        </p>
                                        <h5>Jack danial</h5>
                                        <span>New york</span>
                                    </div>
                                </li> */}
                                </div>
                            </div>
                            <div className="cta-full-wid">
                                <a href="#!" className="cta-dark">
                                    More customer reviews
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* END */}
            {/* BANNER */}
            <section>
                <div className="str">
                    <div className="ban-inn ban-home">
                        <div className="container">
                            <div className="row">
                                <div className="hom-ban">
                                    <div className="ban-tit">
                                        <span>
                                            <i className="no1">#1</i> Wedding Website
                                        </span>
                                        <h2>Why choose us</h2>
                                        <p>Most Trusted and premium Matrimony Service in the World.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* START */}
            <section>
                <div className="ab-sec2">
                    <div className="container">
                        <div className="row">
                            <ul>
                                <li>
                                    <div
                                        className="animate animate__animated animate__slower"
                                        data-ani="animate__flipInX"
                                        data-dely="0.1"
                                    >
                                        <img src="images/icon/prize.png" alt="" loading="lazy" />
                                        <h4>Genuine profiles</h4>
                                        <p>Contact genuine profiles with 100% verified mobile</p>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        className="animate animate__animated animate__slower"
                                        data-ani="animate__flipInX"
                                        data-dely="0.3"
                                    >
                                        <img src="images/icon/trust.png" alt="" loading="lazy" />
                                        <h4>Most trusted</h4>
                                        <p>The most trusted wedding matrimony brand lorem</p>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        className="animate animate__animated animate__slower"
                                        data-ani="animate__flipInX"
                                        data-dely="0.6"
                                    >
                                        <img src="images/icon/rings.png" alt="" loading="lazy" />
                                        <h4>2000+ weddings</h4>
                                        <p>Lakhs of peoples have found their life partner</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* ABOUT START */}
            <section>
                <div className="ab-wel">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ab-wel-lhs">
                                    <span className="ab-wel-3" />
                                    <img
                                        src="https://wevaphotography.com/wp-content/uploads/2021/10/Traditional-South-Indian-Wedding-Ceremony-1024x576.jpg"
                                        alt=""
                                        loading="lazy"
                                        className="ab-wel-1"
                                    />
                                    <img
                                        src="https://cdn0.weddingwire.in/article/9117/3_2/1280/jpg/47119-south-indian-brides-the-wedding-salad-lead.webp"
                                        alt=""
                                        loading="lazy"
                                        className="ab-wel-2"
                                    />
                                    <span className="ab-wel-4" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ab-wel-rhs">
                                    <div className="ab-wel-tit">
                                        <h2>
                                            Welcome to <em>Wedding matrimony</em>
                                        </h2>
                                        <p>
                                            Best wedding matrimony It is a long established fact that a
                                            reader will be distracted by the readable content of a page
                                            when looking at its layout.{" "}
                                        </p>
                                        <p>
                                            {" "}
                                            <a href="plans.html">Click here to</a> Start you matrimony
                                            service now.
                                        </p>
                                    </div>
                                    <div className="ab-wel-tit-1">
                                        <p>
                                            There are many variations of passages of Lorem Ipsum
                                            available, but the majority have suffered alteration in some
                                            form, by injected humour, or randomised words which don't look
                                            even slightly believable.
                                        </p>
                                    </div>
                                    <div className="ab-wel-tit-2">
                                        <ul>
                                            <li>
                                                <div>
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                    <h4>
                                                        Enquiry <em> +91 044 2432 2858</em>
                                                    </h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <i className="fa fa-envelope-o" aria-hidden="true" />
                                                    <h4>
                                                        Get Support <em>  cnnm1985@gmail.com</em>
                                                    </h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* COUNTS START */}
            <section>
                <div className="ab-cont">
                    <div className="container">
                        <div className="row">
                            <ul>
                                <li>
                                    <div className="ab-cont-po">
                                        <i className="fa fa-heart-o" aria-hidden="true" />
                                        <div>
                                            <h4>2K</h4>
                                            <span>Couples pared</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="ab-cont-po">
                                        <i className="fa fa-users" aria-hidden="true" />
                                        <div>
                                            <h4>4000+</h4>
                                            <span>Registerents</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="ab-cont-po">
                                        <i className="fa fa-male" aria-hidden="true" />
                                        <div>
                                            <h4>1600+</h4>
                                            <span>Mens</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="ab-cont-po">
                                        <i className="fa fa-female" aria-hidden="true" />
                                        <div>
                                            <h4>2000+</h4>
                                            <span>Womens</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* MOMENTS START */}
            <section>
                <div className="wedd-tline">
                    <div className="container">
                        <div className="row">
                            <div className="home-tit">
                                <p>Moments</p>
                                <h2>
                                    <span>How it works</span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                            <MomentsSec />
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* RECENT COUPLES */}
            <section>
                <div className="hom-couples-all">
                    <div className="container">
                        <div className="row">
                            <div className="home-tit">
                                <p>trusted brand</p>
                                <h2>
                                    <span>Recent Couples</span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                        </div>
                    </div>
                    <div className="hom-coup-test">
                        <ul className="couple-sli">
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://www.brides.com/thmb/phFLfofOAS5QwbvIjmNwd1hhfLY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/justina-jordan-wedding-sq-b9f66d2a153349e186d1048ddb44f93a.jpg" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://i.pinimg.com/736x/e5/fc/5c/e5fc5c681f7a191f26f8f9babd6c1caa.jpg" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://i.pinimg.com/736x/f3/09/73/f309735cf0fda58fcaff7d9f8fe1379a.jpg" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://i.pinimg.com/736x/49/63/17/496317d5d0ded3faa44a3b3f275aa13c.jpg" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBuWbrrOTKfpqcufkCuemfr9KLrQR1alTU1luDSB03fg&s" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1SWDUCSkNC113SIwm3aNpu59zpmlCVPGhzycHKjhnNw&s" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://cdn0.weddingwire.in/article-gallery-o/00000/3_2/1280/jpg/articulos-india/2019/non-troncales/wedding-couple-dresses/coolbluez-coupleweddingdress-16coupleweddingdress.webp" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding-video.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="hom-coup-box">
                                    <span className="leaf" />
                                    <img src="https://www.kalkifashion.com/blogs/wp-content/uploads/2023/02/An_Ultimate_Guide_To_Gujarati_Wedding_Traditions_Rituals__More.jpg" alt="" loading="lazy" />
                                    <div className="bx">
                                        <h4>
                                            Dany &amp; July <span>New York</span>
                                        </h4>
                                        <a href="wedding.html" className="sml-cta cta-dark">
                                            View more
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* TEAM START */}
            <section>
                <div className="ab-team">
                    <div className="container">
                        <div className="row">
                            <div className="home-tit">
                                <p>OUR PROFESSIONALS</p>
                                <h2>
                                    <span>Meet Our Team</span>
                                </h2>
                                <span className="leaf1" />
                            </div>
                            <ul>
                                <li>
                                    <div>
                                        <img src="https://www.stevendecuba.com/wp-content/uploads/2022/05/30.png" alt="" loading="lazy" />
                                        <h4>Ashley Jen</h4>
                                        <p>Marketing Manager</p>
                                        <ul className="social-light">
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-facebook" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-whatsapp" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-instagram" aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img src="https://images.squarespace-cdn.com/content/v1/51e7ab7de4b0cfebaa144bcf/5495d9b7-5984-47a4-8f25-f9a3583d706f/DR0055Previewsg.jpg" alt="" loading="lazy" />
                                        <h4>Ashley Jen</h4>
                                        <p>Marketing Manager</p>
                                        <ul className="social-light">
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-facebook" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-whatsapp" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-instagram" aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0Llg_aCRcCS5QnaazBza35jR9LaFagmHf4dUWvtyX6g&s" alt="" loading="lazy" />
                                        <h4>Emily Arrov</h4>
                                        <p>Creative Manager</p>
                                        <ul className="social-light">
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-facebook" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-whatsapp" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-instagram" aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTT7maEEfww3riRvE2QmuMTOQ68dalbneBYuUhFs5Ckw&s" alt="" loading="lazy" />
                                        <h4>Julia sear</h4>
                                        <p>Client Coordinator</p>
                                        <ul className="social-light">
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-facebook" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-whatsapp" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i className="fa fa-instagram" aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* GALLERY START */}
            <section>
                <div className="wedd-gall home-wedd-gall">
                    <div className="container d-flex justify-content-center" >
                        <div className="gall-inn">
                            <div className="home-tit">
                                <p>collections</p>
                                <h2>
                                    <span>Photo gallery</span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                            <div className="col-sm-6 col-md-2">
                                <div
                                // className="gal-im animate animate__animated animate__slow"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://femina.wwmindia.com/content/2023/sep/wedding-021695624763.jpg"
                                        className="gal-siz-1"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://www.brides.com/thmb/vxGFGGhcMQjg211eXLaTrXHgbhc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/sq-ea8c12ac4c2946738c7d6c3d9c45692f.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://i.pinimg.com/736x/40/8e/28/408e28f62234eab4f302dff6808864b3.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3" style={{ marginLeft: '10px' }}>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://assets.vogue.in/photos/64411b0713d0fd2111a35cf9/master/pass/INDIAN-WEDIDNG-DRESSES-voguebus-story.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://weddingdocumentary.com/wp-content/uploads/2023/06/Nivi_Kousik_Wedding-76.jpg"
                                        className="gal-siz-1"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2" style={{ marginLeft: '10px' }}>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYBs6zafGcpjFsVPCCFyh-vcmBTFY_jhlCyIZ0UTPO_A&s"
                                        className="gal-siz-1"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://assets.cntraveller.in/photos/63247a4367d00ef33fe82279/master/w_1600%2Cc_limit/05_RHEA_AVIK_WEDDING_RECEPTION-9857-ADI.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3" style={{ marginLeft: '10px' }}>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://www.marthastewart.com/thmb/pgLRzL7C5yOaqDMrP85vOdvVMBE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/grace-dutch-wedding-couple-0919-2000-b4ac7f8409e14d9da66a557aa1758d9f.jpg"
                                        className="gal-siz-2"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                                <div
                                // className="gal-im animate animate__animated animate__slower"
                                // data-ani="animate__flipInX"
                                >
                                    <img
                                        src="https://manhattanbride.com/wp-content/uploads/2019/04/Indian-Traditions39-Ceremony-506-Christopher-Brock-Photography.jpg"
                                        className="gal-siz-1"
                                        alt=""
                                        loading="lazy"
                                    />
                                    <div className="txt">
                                        <span>Wedding</span>
                                        <h6>Bride &amp; Groom</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* BLOG POSTS START */}
            <section>
                <div className="hom-blog">
                    <div className="container">
                        <div className="row">
                            <div className="home-tit">
                                <p>Blog posts</p>
                                <h2>
                                    <span>Blog &amp; Articles</span>
                                </h2>
                                <span className="leaf1" />
                                <span className="tit-ani-" />
                            </div>
                            <div className="blog">
                                <ul>
                                    <li>
                                        <div className="blog-box">
                                            <img src="https://images.travelandleisureasia.com/wp-content/uploads/sites/2/2020/11/Inside-image-Wedding-Photographers6.jpg" alt="" loading="lazy" />
                                            <span>Wedding - Johnny</span>
                                            <h2>Wedding arrangements</h2>
                                            <p>
                                                It is a long established fact that a reader will be
                                                distracted by the readable content.
                                            </p>
                                            <a href="blog-details.html" className="cta-dark">
                                                <span>Read more</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blog-box">
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLZIg49UNJ6zyPBSJTDOQ6wQdx8kZ9kEcnxcwRn1YDKf7dzmvcDcL4jvcuSa4TI-PDkus&usqp=CAU" alt="" loading="lazy" />
                                            <span>Wedding - Johnny</span>
                                            <h2>Wedding arrangements</h2>
                                            <p>
                                                It is a long established fact that a reader will be
                                                distracted by the readable content.
                                            </p>
                                            <a href="blog-details.html" className="cta-dark">
                                                <span>Read more</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blog-box">
                                            <img src="https://i.pinimg.com/736x/57/78/21/5778211829b4ceab5ac0c2b3bac44f92.jpg" alt="" loading="lazy" />
                                            <span>Wedding - Johnny</span>
                                            <h2>Invitation cards</h2>
                                            <p>
                                                It is a long established fact that a reader will be
                                                distracted by the readable content.
                                            </p>
                                            <a href="blog-details.html" className="cta-dark">
                                                <span>Read more</span>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* FIND YOUR MATCH BANNER */}
            <section>
                <div className="str count">
                    <div className="container">
                        <div className="row">
                            <div className="fot-ban-inn">
                                <div className="lhs">
                                    <h2>Find your perfect Match now</h2>
                                    <p>
                                        lacinia viverra lectus. Fusce imperdiet ullamcorper metus eu
                                        fringilla.Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                    <a href="sign-up.html" className="cta-3">
                                        Register Now
                                    </a>
                                    <a href="sign-up.html" className="cta-4">
                                        Help &amp; Support
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Home