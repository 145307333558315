

import React from "react";
import { Chrono } from "react-chrono";

const MomentsSec = () => {
    return <Chrono
        items={[
            {
                // title: "25 July 1940",   
                cardTitle: "Register",
                media: {
                    name: "Battle of britain",
                    source: {
                        url: "images/icon/rings.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            },
            {
                // title: "25 July 1940",   
                cardTitle: "Find your Match",
                media: {
                    name: "Battle of britain",
                    source: {
                        url: "images/icon/wedding-2.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            },
            {
                // title: "25 July 1940",   
                cardTitle: "Send Interest",
                media: {
                    // name: "Battle of britain",
                    source: {
                        url: "images/icon/love-birds.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            },
            {
                // title: "25 July 1940",   
                cardTitle: "Get Profile Information",
                media: {
                    name: "Battle of britain",
                    source: {
                        url: "images/icon/network.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            },
            {
                // title: "25 July 1940",   
                cardTitle: "Start Meetups",
                media: {
                    // name: "Battle of britain",
                    source: {
                        url: "images/icon/chat.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            },
            {
                // title: "25 July 1940",   
                cardTitle: "Getting Marriage",
                media: {
                    // name: "Battle of britain",
                    source: {
                        url: "images/icon/wedding-couple.png"
                    },
                    type: "IMAGE"
                },
                cardSubtitle: `
                Register
                TIMING: 7:00 PM
                `,
                cardDetailedText: `Lorem ipsum dolor, sit amete repellendus, corrupe? Autem ratione exerci`,
            }]}
        mode="VERTICAL"
    />;
};

export default MomentsSec;
