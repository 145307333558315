import React from 'react'

const AllProfile = () => {
  return (
    <>

      {/* SUB-HEADING */}
      <section>
        <div className="all-pro-head">
          <div className="container">
            <div className="row">
              <h1>Lakhs of Happy Marriages</h1>
              <a href="sign-up.html">
                Join now for Free{" "}
                <i className="fa fa-handshake-o" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
        {/*FILTER ON MOBILE VIEW*/}
        <div className="fil-mob fil-mob-act">
          <h4>
            Profile filters <i className="fa fa-filter" aria-hidden="true" />{" "}
          </h4>
        </div>
      </section>
      {/* END */}
      {/* START */}
      <section>
        <div className="all-weddpro all-jobs all-serexp chosenini">
          <div className="container">
            <div className="row">
              <div className="col-md-3 fil-mob-view">
                <span className="filter-clo">+</span>
                {/* START */}
                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-search" aria-hidden="true" /> I'm looking
                    for
                  </h4>
                  <div className="form-group">
                    <select className="chosen-select">
                      <option value="">I'm looking for</option>
                      <option value="Men">Men</option>
                      <option value="Women">Women</option>
                    </select>
                  </div>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    Age
                  </h4>
                  <div className="form-group">
                    <select className="chosen-select">
                      <option value="">Select age</option>
                      <option value="">18 to 30</option>
                      <option value="">31 to 40</option>
                      <option value="">41 to 50</option>
                      <option value="">51 to 60</option>
                      <option value="">61 to 70</option>
                      <option value="">71 to 80</option>
                      <option value="">81 to 90</option>
                      <option value="">91 to 100</option>
                    </select>
                  </div>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-bell-o" aria-hidden="true" />
                    Select Religion
                  </h4>
                  <div className="form-group">
                    <select className="chosen-select">
                      <option>Religion</option>
                      <option>Any</option>
                      <option>Hindu</option>
                      <option>Muslim</option>
                      <option>Jain</option>
                      <option>Christian</option>
                    </select>
                  </div>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    Location
                  </h4>
                  <div className="form-group">
                    <select className="chosen-select" name="addjbmaincate">
                      <option value="Chennai">Chennai</option>
                      <option value="Bangaluru">Bangaluru</option>
                      <option value="Delhi">Delhi</option>
                    </select>
                  </div>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com lhs-rati lhs-avail lhs-cate">
                  <h4>
                    <i className="fa fa-thumbs-o-up" aria-hidden="true" />{" "}
                    Availablity
                  </h4>
                  <ul>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_avail"
                          className="rating_check"
                          id="exav1"
                          defaultChecked=""
                        />
                        <label htmlFor="exav1">All</label>
                      </div>
                    </li>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_avail"
                          className="rating_check"
                          id="exav2"
                        />
                        <label htmlFor="exav2">Available</label>
                      </div>
                    </li>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_avail"
                          className="rating_check"
                          id="exav3"
                        />
                        <label htmlFor="exav3">Offline</label>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com lhs-rati lhs-ver lhs-cate">
                  <h4>
                    <i className="fa fa-shield" aria-hidden="true" />
                    Profile
                  </h4>
                  <ul>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_veri"
                          className="rating_check"
                          id="exver1"
                          defaultChecked=""
                        />
                        <label htmlFor="exver1">All</label>
                      </div>
                    </li>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_veri"
                          className="rating_check"
                          id="exver2"
                        />
                        <label htmlFor="exver2">Premium</label>
                      </div>
                    </li>
                    <li>
                      <div className="rbbox">
                        <input
                          type="radio"
                          defaultValue=""
                          name="expert_veri"
                          className="rating_check"
                          id="exver3"
                        />
                        <label htmlFor="exver3">Free</label>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* END */}
                {/* START */}
                <div className="filt-com filt-send-query">
                  <div className="send-query">
                    <h5>What are you looking for?</h5>
                    <p>We will help you to arrage the best match to you.</p>
                    <a href="#!" data-toggle="modal" data-target="#expfrm">
                      Send your queries
                    </a>
                  </div>
                </div>
                {/* END */}
              </div>
              <div className="col-md-9">
                <div className="short-all">
                  <div className="short-lhs">
                    Showing <b>32</b> profiles
                  </div>
                  <div className="short-rhs">
                    <ul>
                      <li>Sort by:</li>
                      <li>
                        <div className="form-group">
                          <select className="chosen-select">
                            <option value="">Most relative</option>
                            <option value="Men">Date listed: Newest</option>
                            <option value="Men">Date listed: Oldest</option>
                          </select>
                        </div>
                      </li>
                      <li>
                        <div className="sort-grid sort-grid-1">
                          <i className="fa fa-th-large" aria-hidden="true" />
                        </div>
                      </li>
                      <li>
                        <div className="sort-grid sort-grid-2 act">
                          <i className="fa fa-bars" aria-hidden="true" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="all-list-sh">
                  <ul>
                    <li>
                      <div
                        className="all-pro-box user-avil-onli"
                        data-useravil="avilyes"
                        data-aviltxt="Available online"
                      >
                        {/*PROFILE IMAGE*/}
                        <div className="pro-img">
                          <a href="profile-details.html">
                            <img src="images/profiles/4.jpg" alt="" />
                          </a>
                          <div className="pro-ave" title="User currently available">
                            <span className="pro-ave-yes" />
                          </div>
                          <div className="pro-avl-status">
                            <h5>Available Online</h5>
                          </div>
                        </div>
                        {/*END PROFILE IMAGE*/}
                        {/*PROFILE NAME*/}
                        <div className="pro-detail">
                          <h4>
                            <a href="profile-details.html">Ashley emyy</a>
                          </h4>
                          <div className="pro-bio">
                            <span>B.Sc</span>
                            <span>IT Profession</span>
                            <span>29 Yeard old</span>
                            <span>Height: 155Cms</span>
                          </div>
                          <div className="links">
                            <span className="cta-chat">Chat now</span>
                            <a href="#!">WhatsApp</a>
                            <a
                              href="#!"
                              className="cta cta-sendint"
                              data-bs-toggle="modal"
                              data-bs-target="#sendInter"
                            >
                              Send interest
                            </a>
                            <a href="profile-details.html">More detaiils</a>
                          </div>
                        </div>
                        {/*END PROFILE NAME*/}
                        {/*SAVE*/}
                        <span
                          className="enq-sav"
                          data-toggle="tooltip"
                          title="Click to save this provile."
                        >
                          <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                        </span>
                        {/*END SAVE*/}
                      </div>
                    </li>
                    <li>
                      <div
                        className="all-pro-box"
                        data-useravil="avilno"
                        data-aviltxt="Offline"
                      >
                        {/*PROFILE IMAGE*/}
                        <div className="pro-img">
                          <a href="profile-details.html">
                            <img src="images/profiles/10.jpg" alt="" />
                          </a>
                          <div className="pro-ave" title="User currently available">
                            <span className="pro-ave-yes" />
                          </div>
                          <div className="pro-avl-status">
                            <span className="marqu">
                              Last login 10 mins ago | I'll be available on 10:00 AM
                            </span>
                          </div>
                        </div>
                        {/*END PROFILE IMAGE*/}
                        {/*PROFILE NAME*/}
                        <div className="pro-detail">
                          <h4>
                            <a href="profile-details.html">Elizabeth Taylor</a>
                          </h4>
                          <div className="pro-bio">
                            <span>B.Sc</span>
                            <span>IT Profession</span>
                            <span>29 Yeard old</span>
                            <span>Height: 155Cms</span>
                          </div>
                          <div className="links">
                            <span className="cta-chat">Chat now</span>
                            <a href="#!">WhatsApp</a>
                            <a
                              href="#!"
                              className="cta cta-sendint"
                              data-bs-toggle="modal"
                              data-bs-target="#sendInter"
                            >
                              Send interest
                            </a>
                            <a href="profile-details.html">More detaiils</a>
                          </div>
                        </div>
                        {/*END PROFILE NAME*/}
                        {/*SAVE*/}
                        <span
                          className="enq-sav"
                          data-toggle="tooltip"
                          title="Click to save this provile."
                        >
                          <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                        </span>
                        {/*END SAVE*/}
                      </div>
                    </li>
                    <li>
                      <div
                        className="all-pro-box user-avil-onli"
                        data-useravil="avilyes"
                        data-aviltxt="Available online"
                      >
                        {/*PROFILE IMAGE*/}
                        <div className="pro-img">
                          <a href="profile-details.html">
                            <img src="images/profiles/11.jpg" alt="" />
                          </a>
                          <div className="pro-ave" title="User currently available">
                            <span className="pro-ave-yes" />
                          </div>
                          <div className="pro-avl-status">
                            <h5>Available Online</h5>
                          </div>
                        </div>
                        {/*END PROFILE IMAGE*/}
                        {/*PROFILE NAME*/}
                        <div className="pro-detail">
                          <h4>
                            <a href="profile-details.html">Angelina Jolie</a>
                          </h4>
                          <div className="pro-bio">
                            <span>B.Sc</span>
                            <span>IT Profession</span>
                            <span>29 Yeard old</span>
                            <span>Height: 155Cms</span>
                          </div>
                          <div className="links">
                            <span className="cta-chat">Chat now</span>
                            <a href="#!">WhatsApp</a>
                            <a
                              href="#!"
                              className="cta cta-sendint"
                              data-bs-toggle="modal"
                              data-bs-target="#sendInter"
                            >
                              Send interest
                            </a>
                            <a href="profile-details.html">More detaiils</a>
                          </div>
                        </div>
                        {/*END PROFILE NAME*/}
                        {/*END USER AND GET QUOTE*/}
                        {/*SAVE*/}
                        <span
                          className="enq-sav"
                          data-toggle="tooltip"
                          title="Click to save this provile."
                        >
                          <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                        </span>
                        {/*END SAVE*/}
                      </div>
                    </li>
                    <li>
                      <div
                        className="all-pro-box"
                        data-useravil="avilno"
                        data-aviltxt="Offline"
                      >
                        {/*PROFILE IMAGE*/}
                        <div className="pro-img">
                          <a href="profile-details.html">
                            <img src="images/profiles/12.jpg" alt="" />
                          </a>
                          <div className="pro-ave" title="User currently available">
                            <span className="pro-ave-yes" />
                          </div>
                          <div className="pro-avl-status">
                            <h5>Last login 10 mins ago</h5>
                            <span className="marqu">
                              Last login 10 mins ago | I'll be available on 10:00 AM
                            </span>
                          </div>
                        </div>
                        {/*END PROFILE IMAGE*/}
                        {/*PROFILE NAME*/}
                        <div className="pro-detail">
                          <h4>
                            <a href="profile-details.html">Olivia mia</a>
                          </h4>
                          <div className="pro-bio">
                            <span>B.Sc</span>
                            <span>IT Profession</span>
                            <span>29 Yeard old</span>
                            <span>Height: 155Cms</span>
                          </div>
                          <div className="links">
                            <span className="cta-chat">Chat now</span>
                            <a href="#!">WhatsApp</a>
                            <a
                              href="#!"
                              className="cta cta-sendint"
                              data-bs-toggle="modal"
                              data-bs-target="#sendInter"
                            >
                              Send interest
                            </a>
                            <a href="profile-details.html">More detaiils</a>
                          </div>
                        </div>
                        {/*END PROFILE NAME*/}
                        {/*SAVE*/}
                        <span
                          className="enq-sav"
                          data-toggle="tooltip"
                          title="Click to save this provile."
                        >
                          <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                        </span>
                        {/*END SAVE*/}
                      </div>
                    </li>
                    <li>
                      <div
                        className="all-pro-box"
                        data-useravil="avilno"
                        data-aviltxt="Offline"
                      >
                        {/*PROFILE IMAGE*/}
                        <div className="pro-img">
                          <a href="profile-details.html">
                            <img src="images/profiles/13.jpg" alt="" />
                          </a>
                          <div className="pro-ave" title="User currently available">
                            <span className="pro-ave-yes" />
                          </div>
                          <div className="pro-avl-status">
                            <h5>Last login 10 mins ago</h5>
                            <span className="marqu">
                              Last login 10 mins ago | I'll be available on 10:00 AM
                            </span>
                          </div>
                        </div>
                        {/*END PROFILE IMAGE*/}
                        {/*PROFILE NAME*/}
                        <div className="pro-detail">
                          <h4>
                            <a href="profile-details.html">Jennifer</a>
                          </h4>
                          <div className="pro-bio">
                            <span>B.Sc</span>
                            <span>IT Profession</span>
                            <span>29 Yeard old</span>
                            <span>Height: 155Cms</span>
                          </div>
                          <div className="links">
                            <span className="cta-chat">Chat now</span>
                            <a href="#!">WhatsApp</a>
                            <a
                              href="#!"
                              className="cta cta-sendint"
                              data-bs-toggle="modal"
                              data-bs-target="#sendInter"
                            >
                              Send interest
                            </a>
                            <a href="profile-details.html">More detaiils</a>
                          </div>
                        </div>
                        {/*END PROFILE NAME*/}
                        {/*SAVE*/}
                        <span
                          className="enq-sav"
                          data-toggle="tooltip"
                          title="Click to save this provile."
                        >
                          <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                        </span>
                        {/*END SAVE*/}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* END */}
      {/* INTEREST POPUP */}
      <div className="modal fade" id="sendInter">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title seninter-tit">
                Send interest to <span className="intename2">Jolia</span>
              </h4>
              <button type="button" className="close" data-bs-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body seninter">
              <div className="lhs">
                <img src="images/profiles/1.jpg" alt="" className="intephoto2" />
              </div>
              <div className="rhs">
                <h4>
                  Permissions: <span className="intename2">Jolia</span> Can able to
                  view the below details
                </h4>
                <ul>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_about" defaultChecked="" />
                      <label htmlFor="pro_about">About section</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_photo" />
                      <label htmlFor="pro_photo">Photo gallery</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_contact" />
                      <label htmlFor="pro_contact">Contact info</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_person" />
                      <label htmlFor="pro_person">Personal info</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_hobbi" />
                      <label htmlFor="pro_hobbi">Hobbies</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_social" />
                      <label htmlFor="pro_social">Social media</label>
                    </div>
                  </li>
                </ul>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    id="comment"
                    name="text"
                    placeholder="Comment goes here"
                    defaultValue={""}
                  />
                  <label htmlFor="comment">
                    Write some message to <span className="intename" />
                  </label>
                </div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Send interest
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END INTEREST POPUP */}
      {/* CHAT CONVERSATION BOX START */}
      <div className="chatbox">
        <span className="comm-msg-pop-clo">
          <i className="fa fa-times" aria-hidden="true" />
        </span>
        <div className="inn">
          <form name="new_chat_form" method="post">
            <div className="s1">
              <img src="images/user/2.jpg" className="intephoto2" alt="" />
              <h4>
                <b className="intename2">Julia</b>,
              </h4>
              <span className="avlsta avilyes">Available online</span>
            </div>
            <div className="s2 chat-box-messages">
              <span className="chat-wel">Start a new chat!!! now</span>
              <div className="chat-con">
                <div className="chat-lhs">Hi</div>
                <div className="chat-rhs">Hi</div>
              </div>
              {/*<span>Start A New Chat!!! Now</span>*/}
            </div>
            <div className="s3">
              <input
                type="text"
                name="chat_message"
                placeholder="Type a message here.."
                required=""
              />
              <button id="chat_send1" name="chat_send" type="submit">
                Send <i className="fa fa-paper-plane-o" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* END */}
    </>

  )
}

export default AllProfile