import React from 'react'

const MakeReservation = () => {
    return (
        <>

            {/* REGISTER */}
            <section>
                <div className="login">
                    <div className="container">
                        <div className="row">
                            <div className="inn">
                                <div className="lhs">
                                    <div className="tit">
                                        <h2>
                                            Are you <b>Planning to Attend?</b> then start Reservation
                                        </h2>
                                    </div>
                                    <div className="im">
                                        <img src="images/login-couple.png" alt="" />
                                    </div>
                                    <div className="log-bg">&nbsp;</div>
                                </div>
                                <div className="rhs">
                                    <div>
                                        <div className="form-tit">
                                            <h4>WILL YOU ATTEND?</h4>
                                            <h1>Start Reservation</h1>
                                            <p>
                                                Already a member? <a href="login.html">Login</a>
                                            </p>
                                        </div>
                                        <div className="form-login chosenini">
                                            <form
                                                className="cform fvali"
                                                method="post"
                                                action="mail/mail-reservation.php"
                                            >
                                                <div className="form-group">
                                                    <label className="lb">Name:</label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Enter your full name"
                                                        name="name"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="lb">Email:</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="lb">Phone:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="phone"
                                                        placeholder="Enter phone number"
                                                        name="phone"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="lb">No of guests</label>
                                                    <select
                                                        className="chosen-select"
                                                        id="no_of_gusts"
                                                        name="no_of_gusts"
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="lb">Meal</label>
                                                    <select
                                                        className="chosen-select"
                                                        id="meal_pref"
                                                        name="meal_pref"
                                                    >
                                                        <option disabled="disabled" selected="">
                                                            Meal Preferences
                                                        </option>
                                                        <option>Only Veg</option>
                                                        <option>Only Non-Veg</option>
                                                        <option>Chicken Soup</option>
                                                        <option>Chicken Soup</option>
                                                        <option>Motton Kabab</option>
                                                        <option>Chicken BBQ</option>
                                                        <option>Mix Salad</option>
                                                        <option>Beef Ribs </option>
                                                    </select>
                                                </div>
                                                <button type="submit" className="btn btn-primary cta-dark">
                                                    Reservation
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}

        </>

    )
}

export default MakeReservation