import React from 'react';
import { Link } from 'react-router-dom'


const Header = () => {
    return (
        <>
            <div className="head-top">
                <div className="container">
                    <div className="row">
                        <div className="topBar">
                            <ul className='topBarList mb-0'>
                                <li>
                                    <i className="fa fa-search" aria-hidden="true" />

                                </li>
                                <li>
                                    <Link to='/About'>

                                        About

                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Faq" >
                                        FAQ

                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Contact" >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                            {/* </div> */}
                            {/* <div className="rhs"> */}
                            <ul className='topRightList mb-0'>
                                <li>
                                    <a href="tel:+91 044 2432 2858">
                                        <i className="fa fa-phone" aria-hidden="true" />
                                        &nbsp;+91 044 2432 2858
                                    </a>
                                </li>
                                <li>

                                    <a href="mailto:cnnm1985@gmail.com">

                                        <i className="fa fa-envelope-o" aria-hidden="true" />
                                        &nbsp; cnnm1985@gmail.com
                                    </a>
                                </li>
                                <li>

                                    <i className="fa fa-facebook" aria-hidden="true" />

                                </li>
                                <li>

                                    <i className="fa fa-twitter" aria-hidden="true" />

                                </li>
                                <li>

                                    <i className="fa fa-whatsapp" aria-hidden="true" />

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* END */}
            {/* MENU POPUP */}
            <div className="menu-pop menu-pop1">
                <span className="menu-pop-clo">
                    <i className="fa fa-times" aria-hidden="true" />
                </span>
                <div className="inn">
                    <img
                        src="images/logo-b.jpeg"
                        alt=""
                        loading="lazy"
                        className="logo-brand-only"
                    />
                    <p>
                        <strong>Best Wedding Matrimony</strong> lacinia viverra lectus. Fusce
                        imperdiet ullamcorper metus eu fringilla.Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.
                    </p>
                    <ul className="menu-pop-info">
                        <li>

                            <i className="fa fa-phone" aria-hidden="true" />
                            +92 (8800) 68 - 8960

                        </li>
                        <li>

                            <i className="fa fa-whatsapp" aria-hidden="true" />
                            +92 (8800) 68 - 8960

                        </li>
                        <li>

                            <i className="fa fa-envelope-o" aria-hidden="true" />
                            help@company.com

                        </li>
                        <li>

                            <i className="fa fa-map-marker" aria-hidden="true" />
                            3812 Lena Lane City Jackson Mississippi

                        </li>
                    </ul>
                    <div className="menu-pop-help">
                        <h4>Support Team</h4>
                        <div className="user-pro">
                            <img src="images/profiles/1.jpg" alt="" loading="lazy" />
                        </div>
                        <div className="user-bio">
                            <h5>Ashley emyy</h5>
                            <span>Senior personal advisor</span>
                            <Link to="/Enquiry" >

                                <a className="btn btn-primary btn-sm">
                                    Ask your doubts
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="menu-pop-soci">
                        <ul>
                            <li>

                                <i className="fa fa-facebook" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-twitter" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-whatsapp" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-linkedin" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-youtube-play" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-instagram" aria-hidden="true" />

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* END */}


            {/* CONTACT EXPERT */}
            <div className="menu-pop menu-pop2">
                <span className="menu-pop-clo">
                    <i className="fa fa-times" aria-hidden="true" />
                </span>
                <div className="inn">
                    <div className="menu-pop-help">
                        <h4>Support Team</h4>
                        <div className="user-pro">
                            <img src="images/profiles/1.jpg" alt="" loading="lazy" />
                        </div>
                        <div className="user-bio">
                            <h5>Ashley emyy</h5>
                            <span>Senior personal advisor</span>
                            <Link to="/Enquiry" >


                                <a className="btn btn-primary btn-sm">
                                    Ask your doubts
                                </a>

                            </Link>
                        </div>
                    </div>
                    <div className="menu-pop-soci">
                        <ul>
                            <li>

                                <i className="fa fa-facebook" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-twitter" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-whatsapp" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-linkedin" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-youtube-play" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-instagram" aria-hidden="true" />

                            </li>
                        </ul>
                    </div>
                    <div className="late-news">
                        <h4>Latest news</h4>
                        <ul>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/1.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to="/BlogDetail">


                                    <a className="fclick" />
                                </Link>
                            </li>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/3.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to="/BlogDetail">

                                    <a className="fclick" />

                                </Link>
                            </li>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/4.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to="/BlogDetail">

                                    <a className="fclick" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* HELP BOX */}
                    <div className="prof-rhs-help">
                        <div className="inn">
                            <h3>Tell us your Needs</h3>
                            <p>Tell us what kind of service you are looking for.</p>
                            <Link to="/Enquiry">
                                Register for free
                            </Link>
                        </div>
                    </div>
                    {/* END HELP BOX */}
                </div>
            </div>
            {/* END */}
            {/* MAIN MENU */}
            <div className="hom-top">
                <div className="container">
                    <div className="row">
                        <div className="hom-nav">
                            {/* LOGO */}
                            <div className="logo d-flex justify-content-between">
                                {/* <span className="menu desk-menu">
                                    <i />
                                    <i />
                                    <i />
                                </span> */}

                                <span className="logo-brand">
                                    <Link to="/">
                                        <img
                                            src="images/logo-main.png"
                                            alt=""
                                            loading="lazy"
                                            className="ic-logo"
                                        />
                                        <br />
                                        {/* <span className="logoCont">
                                            CHENNAI NANBARGAL NALA MANDRAM
                                        </span> */}
                                    </Link>
                                </span>

                            </div>
                            {/* EXPLORE MENU */}
                            <div className="bl">
                                <ul>
                                    <li className="smenu-pare">
                                        <span >
                                            <Link to="/">

                                                HOME
                                            </Link></span>

                                    </li>
                                    {/* <li className="smenu-pare">
                                        <span className="smenu">Explore</span>
                                        <div className="smenu-open smenu-box">
                                            <div className="container">
                                                <div className="row">
                                                    <h4 className="tit">Explore category</h4>
                                                    <ul>
                                                        <li>
                                                            <div className="menu-box menu-box-2">
                                                                <h5>
                                                                    Browse profiles{" "}
                                                                    <span>1200+ Verified profiles</span>
                                                                </h5>
                                                                <span className="explor-cta">More details</span>

                                                                <Link to="/AllProfile">
                                                                    <a className="fclick" />
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="menu-box menu-box-1">
                                                                <h5>
                                                                    Wedding page <span>Make reservation</span>
                                                                </h5>
                                                                <span className="explor-cta">More details</span>

                                                                <Link to='/Wedding' >


                                                                    <a className="fclick" />
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="menu-box menu-box-3">
                                                                <h5>
                                                                    All Services<span>Lorem ipsum dummy</span>
                                                                </h5>
                                                                <span className="explor-cta">More details</span>

                                                                <Link to='Services'>


                                                                    <a className="fclick" />
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="menu-box menu-box-4">
                                                                <h5>
                                                                    Join Now <span>Lorem ipsum dummy</span>
                                                                </h5>
                                                                <span className="explor-cta">More details</span>

                                                                <Link to='/Plans'>

                                                                    <a className="fclick" />

                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}
                                    <li className="smenu-pare">
                                        <span className="">
                                            <Link to="/About">


                                                About
                                            </Link>


                                        </span>
                                        {/* <div className="smenu-open smenu-multi">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="multi-col">
                                                        <div className="inn">
                                                            <h4>Page sets 1</h4>
                                                            <ul>
                                                                <li>
                                                                    <Link to='/AllProfile'>
                                                                        All profiles
                                                                    </Link>
                                                                </li>
                                                                <li>

                                                                    <Link to='/ProfileDetails'>
                                                                        Profile details
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/Wedding'>

                                                                        Wedding
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/WeddingVideo'>
                                                                        Wedding video
                                                                    </Link>

                                                                </li>
                                                                <li>
                                                                    <Link to='/Services'>
                                                                        Our Services
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="multi-col">
                                                        <div className="inn">
                                                            <h4>Page sets 2</h4>
                                                            <ul>
                                                                <li>
                                                                    <Link to='/Plans'>
                                                                        Pricing plans
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/Login'>
                                                                        Login
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/SignUp'>
                                                                        Sign-up
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/PhotoGallery'>

                                                                        Photo gallery
                                                                    </Link>
                                                                </li>
                                                                <li>

                                                                    <Link to='/PhotoGallery'>
                                                                        Photo gallery 1
                                                                    </Link>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="multi-col">
                                                        <div className="inn">
                                                            <h4>Page sets 3</h4>
                                                            <ul>
                                                                <li>
                                                                    <Link to='/Contact'>
                                                                        Contact
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/About">


                                                                        About
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/Blog'>
                                                                        Blog
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/BlogDetail'>
                                                                        Blog detail
                                                                    </Link>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="multi-col">
                                                        <div className="inn">
                                                            <h4>Page sets 4</h4>
                                                            <ul>
                                                                <li>
                                                                    <Link to='/Enquiry'>
                                                                        Ask your doubts
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/MakeReservation'>

                                                                        Make Reservation

                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/Faq'>
                                                                        FAQ
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/CommingSoon'>

                                                                        Coming soon

                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/PageNotFound'>
                                                                        404
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="multi-col full">
                                                        <div className="inn">
                                                            <h4>User dashboard pages</h4>
                                                            <ul>
                                                                <li>
                                                                    <Link to='/UserDashBoard'>
                                                                        Dashboard
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserProfile'>
                                                                        My profile
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserInterests'>
                                                                        Interests
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserChat'>
                                                                        Chat lists
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserPlan'>
                                                                        My plan details
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserProfile'>
                                                                        Profile settings
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/UserProfileEdit'>

                                                                        Edit full profile

                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/Login'>
                                                                        Sign in
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </li>
                                    <li className="smenu-pare">
                                        <span className="smeu">

                                            <Link to='/AllProfile'>
                                                All profiles
                                            </Link>
                                        </span>
                                        {/* <div className="smenu-open smenu-single">
                                            <ul>
                                                <li>

                                                    <Link to='/AllProfile'>
                                                        All profiles
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/ProfileDetails'>
                                                        Profile details
                                                    </Link>
                                                </li>
                                                <li>

                                                    <Link to='/Wedding'>
                                                        Wedding
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/Blog'>
                                                        Blog
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/BlogDetail'>
                                                        Blog detail
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/About" >
                                                        About
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Contact" >
                                                        Contact
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/PhotoGallery" >
                                                        Photo gallery
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/PhotoGallery_1" >
                                                        Photo gallery 1
                                                    </Link>

                                                </li>
                                                <li>
                                                    <Link to="/Login" >
                                                        Login
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/SignUp" >
                                                        Sign-up
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Plans" >
                                                        Pricing plans
                                                    </Link>


                                                </li>
                                            </ul>
                                        </div> */}
                                    </li>
                                    <li>
                                        <Link to="/Plans" >
                                            Plans
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="/SignUp">

                                            Register
                                        </Link>
                                    </li>
                                    <li className="smenu-pare">
                                        <span className="">
                                            {/* <Link to="/UserProfile">
                                                My profile
                                            </Link> */}
                                            <Link to="/Login" className='p-0'>
                                                <button className='loginBtn'>Login</button>
                                            </Link>
                                        </span>
                                        {/* <div className="smenu-open smenu-single">
                                            <ul>
                                                <li>

                                                    <Link to="/UserDashBoard">
                                                        Dashboard
                                                    </Link>
                                                </li>


                                                <li>

                                                    <Link to="/UserInterests">
                                                        Interests
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/UserChat">
                                                        Chat lists
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/UserPlan">
                                                        My plan details
                                                    </Link>

                                                </li>
                                                <li>
                                                    <Link to="/UserSetting">

                                                        Profile settings
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/UserProfileEdit">
                                                        Edit full profile
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Login">
                                                        Sign in
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </li>
                                </ul>
                            </div>
                            {/* USER PROFILE */}
                            <div className="al">
                                <div className="head-pro">
                                    <img src="images/profiles/1.jpg" alt="" loading="lazy" />
                                    <b>Advisor</b>
                                    <br />
                                    <h4>Ashley emyy</h4>
                                    <span className="fclick" />
                                </div>
                            </div>
                            {/*MOBILE MENU*/}
                            {/* <div className="mob-menu">
                                <div className="mob-me-ic">
                                    <span className="ser-open mobile-ser">
                                        <img src="images/icon/search.svg" alt="" />
                                    </span>
                                    <span className="mobile-exprt" data-mob="dashbord">
                                        <img src="images/icon/users.svg" alt="" />
                                    </span>
                                    <span className="mobile-menu" data-mob="mobile">
                                        <img src="images/icon/menu.svg" alt="" />
                                    </span>
                                </div>
                            </div> */}
                            {/*END MOBILE MENU*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* END */}
            {/* EXPLORE MENU POPUP */}
            <div className="mob-me-all mobile_menu">
                <div className="mob-me-clo">
                    <img src="images/icon/close.svg" alt="" />
                </div>
                <div className="mv-bus">
                    <h4>
                        <i className="fa fa-globe" aria-hidden="true" /> EXPLORE CATEGORY
                    </h4>
                    <ul>
                        <li>
                            <Link to="/AllProfile">
                                Browse profiles
                            </Link>
                        </li>
                        <li>
                            <Link to="/Wedding">
                                Wedding page
                            </Link>
                        </li>
                        <li>
                            <Link to="/Services">
                                All Services
                            </Link>
                        </li>
                        <li>
                            <Link to="/Plans">
                                Join Now
                            </Link>
                        </li>
                    </ul>
                    <h4>
                        <i className="fa fa-align-center" aria-hidden="true" /> All Pages
                    </h4>
                    <ul>
                        <li>
                            <Link to="/AllProfile">
                                All profiles
                            </Link>
                        </li>
                        <li>
                            <Link to="/ProfileDetails">
                                Profile details
                            </Link>
                        </li>
                        <li>
                            <Link to="/Wedding">
                                Wedding
                            </Link>
                        </li>
                        <li>
                            <Link to="/WeddingVideo">
                                Wedding video
                            </Link>
                        </li>
                        <li>
                            <Link to="/Services">
                                Our Services
                            </Link>
                        </li>
                        <li>
                            <Link to="/Plans">
                                Pricing plans
                            </Link>
                        </li>
                        <li>
                            <Link to="/Login">
                                Login
                            </Link>
                        </li>
                        <li>
                            <Link to="/SignUp">
                                Sign-up
                            </Link>
                        </li>
                        <li>
                            <Link to="/PhotoGallery">
                                Photo gallery
                            </Link>
                        </li>
                        <li>
                            <Link to="/PhotoGallery_1">
                                Photo gallery 1
                            </Link>
                        </li>
                        <li>
                            <Link to="/Contact">
                                Contact
                            </Link>


                        </li>
                        <li>
                            <Link to="/About">

                                About

                            </Link>
                        </li>
                        <li>
                            <Link to="/Blog">
                                Blog
                            </Link>
                        </li>
                        <li>
                            <Link to="/BlogDetail">
                                Blog detail
                            </Link>
                        </li>
                        <li>
                            <Link to="/Enquiry">
                                Ask your doubts
                            </Link>
                        </li>
                        <li>
                            <Link to="/MakeReservation">
                                Make Reservation
                            </Link>
                        </li>
                        <li>
                            <Link to="/Faq">
                                FAQ
                            </Link>
                        </li>
                        <li>
                            <Link to="/CommingSoon">

                                Coming soon

                            </Link>
                        </li>
                        <li>
                            <Link to="/PageNotFound">
                                404
                            </Link>
                        </li>
                    </ul>
                    <div className="menu-pop-help">
                        <h4>Support Team</h4>
                        <div className="user-pro">
                            <img src="images/profiles/1.jpg" alt="" loading="lazy" />
                        </div>
                        <div className="user-bio">
                            <h5>Ashley emyy</h5>
                            <span>Senior personal advisor</span>


                            <Link to='/Enquiry'>

                                <a className="btn btn-primary btn-sm">
                                    Ask your doubts


                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="menu-pop-soci">
                        <ul>
                            <li>

                                <i className="fa fa-facebook" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-twitter" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-whatsapp" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-linkedin" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-youtube-play" aria-hidden="true" />

                            </li>
                            <li>

                                <i className="fa fa-instagram" aria-hidden="true" />

                            </li>
                        </ul>
                    </div>
                    <div className="late-news">
                        <h4>Latest news</h4>
                        <ul>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/1.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to='/BlogDetail'>
                                    <a className="fclick" />
                                </Link>
                            </li>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/3.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to='/BlogDetail'>
                                    <a className="fclick" />
                                </Link>
                            </li>
                            <li>
                                <div className="rel-pro-img">
                                    <img src="images/couples/4.jpg" alt="" loading="lazy" />
                                </div>
                                <div className="rel-pro-con">
                                    <h5>Long established fact that a reader distracted</h5>
                                    <span className="ic-date">12 Dec 2023</span>
                                </div>
                                <Link to='/BlogDetail'>
                                    <a className="fclick" />
                                </Link>

                            </li>
                        </ul>
                    </div>
                    <div className="prof-rhs-help">
                        <div className="inn">
                            <h3>Tell us your Needs</h3>
                            <p>Tell us what kind of service you are looking for.</p>
                            <Link to='/Enquiry'>
                                Register for free
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mob-me-all dashbord_menu">
                <div className="mob-me-clo">
                    <img src="images/icon/close.svg" alt="" />
                </div>
                <div className="mv-bus">
                    <div className="head-pro">
                        <img src="images/profiles/1.jpg" alt="" loading="lazy" />
                        <b>user profile</b>
                        <br />
                        <h4>Ashley emyy</h4>
                    </div>
                    <ul>
                        <li>
                            <Link to='/Login'>
                                Login
                            </Link>
                        </li>
                        <li>
                            <Link to='/SignUp'>
                                Sign-up
                            </Link>
                        </li>
                        <li>
                            <Link to='/Plans'>
                                Pricing plans
                            </Link>

                        </li>
                        <li>
                            <Link to='/AllProfile'>
                                Browse profiles
                            </Link>

                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header