import React, { useState } from 'react';

import './styles/loginForm.css'

const DemoLogin = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        username: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();
        // Add your logic to handle form submission (e.g., API call, etc.)
    };

    const validateForm = () => {
        const { username, password } = formData;
        let errorsObj = { username: '', password: '' };
        let isValid = true;

        if (!username) {
            errorsObj.username = 'Username is required';
            isValid = false;
        }

        if (!password) {
            errorsObj.password = 'Password is required';
            isValid = false;
        }

        setErrors(errorsObj);
        return isValid;
    };

    return (
        <div className='loginFormCont'>
            <div className="loginForm">


                <h2>Login Form</h2>

                <form onSubmit={handleSubmit} >
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="text" class="form-control" id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange} placeholder="Enter your Name" />
                        <span>     {errors.username && <p className="error">{errors.username}</p>}</span>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange} placeholder="Password" />
                        <span>     {errors.password && <p className="error">{errors.password}</p>}</span>
                    </div>

                    <button type="submit" class="btn btn-primary submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default DemoLogin;
